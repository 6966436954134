






















































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { Watch } from 'vue-property-decorator'
import DateField from '@/components/inputs/DateField.vue'
import { DateTime, Duration } from 'luxon'
import { vxm } from '@/store'
import Booking from '@/models/booking/Booking'
import BookingDialog from '@/components/booking/BookingDialog.vue'
import DocumentIdentifier from '@/models/document/DocumentIdentifier'
import DocumentDialog from '@/components/document/DocumentDialog.vue'
import ThresholdsList from '@/components/tyre/Thresholds.vue'
import TreadDepthThresholds from '@/models/tyre/TreadDepthThresholds'
import TreadDepthThresholdsService from '@/services/tyre/TreadDepthThresholdsService'
import printJS from 'print-js-updated'

@Component({
  components: {
    ListView,
    DateField,
    BookingDialog,
    DocumentDialog,
    ThresholdsList,
  },
})
export default class List extends Vue {
  private routes = {}
  private rowActions = []
  private topActions = []
  private calendars = []
  private places = []
  private resources = []
  private services = []
  private bookings = []

  private calendarId: number = null
  private placeId = ''
  private fromDate = ''
  private toDate = ''
  private fromDateInput = ''
  private toDateInput = ''
  private hidePaidOrders = false
  private showResource = false
  private showCancelledBookings = false
  private refreshAutomatically = false
  private refreshMilliSecPerStep = 1000
  private refreshAfterSteps = 60
  private refreshCurrentStep = 0
  private refreshIntervalId = null
  private refreshUniqueUrlId = ''

  private bookingPopupVisible = false
  private booking = null

  private documentDialogVisible = false
  private document = null
  private thresholdsLevels = {
    winter: {},
    summer: {},
  } as TreadDepthThresholds

  private created() {
    this.paramsFromQuery()

    TreadDepthThresholdsService.loadConfig()
      .then((thresholdsLevels) => {
        this.thresholdsLevels = thresholdsLevels
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
  }

  // On startup, load parameters from URL query
  private paramsFromQuery() {
    if (this.$route.query.start && this.$route.query.end) {
      this.fromDateInput = (this.$route.query.start as string) || ''
      this.toDateInput = (this.$route.query.end as string) || ''
      this.submitPeriod()
    } else if (this.$route.query.today) {
      this.selectPeriod('today')
    }
    if (this.$route.query.place) {
      this.placeId = this.$route.query.place as string
    }
    this.showResource = this.getStoredParamBool('showResource')
    this.showCancelledBookings = this.getStoredParamBool('showCancelledBookings')
    this.hidePaidOrders = this.getStoredParamBool('hidePaidOrders')
    this.refreshAutomatically = this.getStoredParamBool('refreshAutomatically')
  }

  @Watch('showResource')
  private onChangeShowResource() {
    this.setLocalStorageBool('showResource', this.showResource)
  }

  @Watch('showCancelledBookings')
  private onChangeShowCancelledBookings() {
    this.setLocalStorageBool('showCancelledBookings', this.showCancelledBookings)
  }

  @Watch('hidePaidOrders')
  private onChangeHidePaidOrders() {
    this.setLocalStorageBool('hidePaidOrders', this.hidePaidOrders)
  }

  @Watch('refreshAutomatically')
  private onChangeRefreshAutomatically(value: boolean, oldValue: boolean) {
    this.setLocalStorageBool('refreshAutomatically', this.refreshAutomatically)
    if (value && !oldValue) {
      this.refreshIntervalId = setInterval(() => {
        this.refreshCurrentStep += 1
        if (this.refreshCurrentStep > this.refreshAfterSteps) {
          this.refreshCurrentStep = 0
          this.reload()
        }
      }, this.refreshMilliSecPerStep)
    } else if (!value && oldValue) {
      clearInterval(this.refreshIntervalId)
      this.refreshIntervalId = null
    }
  }

  private getStoredParamBool(key: string): boolean {
    if (this.$route.query[key] !== undefined) {
      return !!this.$route.query[key]
    } else {
      return this.getLocalStorageBool(key)
    }
  }

  private getLocalStorageBool(key: string): boolean {
    return !!localStorage.getItem('booking-list-' + key)
  }

  private setLocalStorageBool(key: string, value: boolean) {
    localStorage.setItem('booking-list-' + key, value ? '1' : '')
  }

  private thresholds(season) {
    return season === 'Winter' ? this.thresholdsLevels.winter : this.thresholdsLevels.summer
  }

  // When params change, pass them as query to ListView, which will
  // cause it include them in browser URL, together with its own "url persistent" params.
  private get query() {
    if (!this.validateDates(false)) {
      const params = new URLSearchParams(window.location.search)
      params.delete('start')
      params.delete('end')
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`)
    } else {
      const params = new URLSearchParams(window.location.search)
      params.set('start', this.fromDate)
      params.set('end', this.toDate)
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`)
    }
    const params = {
      hidePaid: '',
      showCancelled: '',
      autoRefresh: '',
      start: '',
      end: '',
      place: '',
      _refresh: '',
    }
    if (this.hidePaidOrders) {
      params.hidePaid = '1'
    }
    if (this.showCancelledBookings) {
      params.showCancelled = '1'
    }
    if (this.refreshAutomatically) {
      params.autoRefresh = '1'
    }
    if (this.fromDate) {
      params.start = this.fromDate
    }
    if (this.toDate) {
      params.end = this.toDate
    }
    if (this.placeId) {
      params.place = this.placeId
    }
    if (this.refreshUniqueUrlId) {
      params._refresh = this.refreshUniqueUrlId
    }
    return params
  }

  // URL to fetch data from backend
  // We include the query params from above, and ListView will append its own if needed (sorting etc)
  private get url() {
    if (!this.calendarId) {
      return ''
    }

    return '/v4/site/calendars/' + this.calendarId + '/bookings'
  }

  private mounted() {
    this.rowActions = [
      {
        id: 'show',
        click: (_, item) => {
          this.bookingPopupVisible = true
          this.booking = item.clone()
        },
      },
      {
        id: 'workorder',
        click: (_component, item) => {
          this.document = new DocumentIdentifier()
          this.document.type = 'WorkOrderForBooking'
          this.document.params = { booking_id: item.id }
          this.documentDialogVisible = true
        },
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return { name: 'Booking/New' }
        },
      },
      {
        id: 'printWorkOrders',
        label: 'Print work orders',
        type: 'link',
        click: () => {
          if (this.validateDates()) {
            this.document = new DocumentIdentifier()
            this.document.type = 'WorkOrdersForBookingList'
            this.document.params = { calendar_id: this.calendarId, start_date: this.fromDate, end_date: this.toDate }
            this.documentDialogVisible = true
          }
        },
      },
      {
        id: 'printMiniWorkOrders',
        label: 'Print mini work orders',
        type: 'link',
        click: () => {
          if (this.validateDates()) {
            this.document = new DocumentIdentifier()
            this.document.type = 'MiniWorkOrdersForBookingList'
            this.document.params = { calendar_id: this.calendarId, start_date: this.fromDate, end_date: this.toDate }
            this.documentDialogVisible = true
          }
        },
      },
      {
        id: 'printPickList',
        label: 'Print pick list',
        type: 'link',
        click: () => {
          const data = []
          this.bookings.forEach((b) => {
            let time = b.displayTime
            if (this.isPeriodMultipleDays) {
              time += '<br/>' + b.displayDate
            }
            data.push({
              time: time,
              licenseplate: b.carLicenseplate,
              position: b.tyreHotelMetaData?.stockPositionName || '',
              treadDepths: b.tyreHotelMetaData?.treadDepths ? b.tyreHotelMetaData.treadDepths.join(', ') : '',
              service: b.primaryServiceName,
            })
          })
          printJS({
            printable: data,
            type: 'json',
            documentTitle: 'Print pick list',
            properties: [
              { field: 'time', displayName: this.$t('Time') },
              { field: 'licenseplate', displayName: this.$t('Licenseplate') },
              { field: 'position', displayName: this.$t('Stock position') },
              { field: 'treadDepths', displayName: this.$t('Tread depths') },
              { field: 'service', displayName: this.$t('Service') },
            ],
          })
        },
      },
      {
        id: 'printOverview',
        label: 'Print overview',
        type: 'link',
        click: () => {
          window.print()
        },
      },
    ]

    if (vxm.user.hasFeatureBookingCalendarView) {
      this.topActions.push({
        id: 'calendar',
        label: 'Calendar',
        type: 'link',
        route: () => {
          return { name: 'Booking/Calendar' }
        },
      })
    }

    this.loadCalendars(() => {
      this.loadPlaces(() => {
        this.loadResources(() => {
          this.loadServices()
        })
      })
    })
  }

  private get headers() {
    const headers = [
      {
        text: 'c:booking:Ticket',
        value: 'ticket',
        filter: { focus: true },
      },
      {
        text: 'Car',
        value: 'carLicenseplate',
      },
      {
        text: 'Time',
        value: 'time',
        filter: { disable: true },
      },
      {
        text: 'Name',
        value: 'contactName',
      },
      {
        text: 'Service',
        value: 'serviceId',
        filter: { disable: true },
      },
    ]

    if (this.showResource) {
      headers.push({
        text: 'Resource',
        value: 'resource',
      })
    }

    const more = [
      {
        text: 'Tyre hotel',
        value: 'tyreHotelId',
      },
      {
        text: 'Order',
        value: 'orderId',
      },
      {
        text: 'Comment',
        value: 'comment',
      },
    ]

    for (let i = 0; i < more.length; i++) {
      headers.push(more[i])
    }

    if (this.places.length > 2 && !this.placeId) {
      headers.push({
        text: 'Place',
        value: 'placeId',
        filter: { disable: true },
      })
    }
    headers.push({
      text: 'Actions',
      value: 'actions',
    })
    return headers
  }

  @Watch('calendarId')
  private onCalendarChange(newId: number, oldId: number) {
    if (newId !== oldId) {
      this.placeId = ''
      this.loadPlaces()
    }
  }

  public onData(data): void {
    this.bookings = data.items
  }

  private loadPlaces(callback = null) {
    this.$axios
      .get('/v4/site/calendars/' + this.calendarId + '/places')
      .then((response) => {
        this.places = [{ id: '', name: this.$t('All') }]
        response.data.data.forEach((v) => {
          this.places.push(v)
        })
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private loadResources(callback = null) {
    this.$axios
      .get('/v4/site/calendars/' + this.calendarId + '/resources')
      .then((response) => {
        this.resources = [{ id: '', name: this.$t('All') }]
        response.data.data.forEach((v) => {
          this.resources.push(v)
        })
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private loadServices(callback = null) {
    this.$axios
      .get('/v4/site/calendars/' + this.calendarId + '/services')
      .then((response) => {
        this.services = [{ id: '', name: this.$t('All') }]
        response.data.data.forEach((v) => {
          this.services.push(v)
        })
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private loadCalendars(callback = null) {
    this.$axios
      .get('/v4/site/calendars')
      .then((response) => {
        this.calendars = response?.data?.data || []
        if (this.calendars.length === 1) {
          this.calendarId = this.calendars[0].id
        } else if (this.calendars.length > 1) {
          for (let i = 0; i < this.calendars.length; i++) {
            if (this.calendars[i].isDefault) {
              this.calendarId = this.calendars[i].id
            }
          }
        }
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private get refreshLabel() {
    const suffix = this.refreshAutomatically ? ' (' + (this.refreshAfterSteps - this.refreshCurrentStep) + 's)' : ''
    return this.$t('Auto reload') + suffix
  }

  private reload() {
    this.refreshUniqueUrlId = new Date().getTime().toString()
  }

  private selectPeriod(value: string) {
    const templates = this.getPeriodTemplates()

    switch (value) {
      case 'today':
        this.fromDateInput = templates.today.toFormat(templates.format)
        this.toDateInput = templates.today.toFormat(templates.format)
        break
      case 'tomorrow':
        this.fromDateInput = templates.tomorrow.toFormat(templates.format)
        this.toDateInput = templates.tomorrow.toFormat(templates.format)
        break
      case 'week':
        this.fromDateInput = templates.today.toFormat(templates.format)
        this.toDateInput = templates.endOfWeek.toFormat(templates.format)
        break
      case 'all':
        this.fromDateInput = ''
        this.toDateInput = ''
        break
    }

    this.submitPeriod()
  }

  private get isSortDesc() {
    return !(this.isPeriod('today') || this.isPeriod('tomorrow'))
  }

  private isPeriod(value: string): boolean {
    const templates = this.getPeriodTemplates()
    switch (value) {
      case 'today':
        return (
          this.fromDate === templates.today.toFormat(templates.format) &&
          this.toDate === templates.today.toFormat(templates.format)
        )
      case 'tomorrow':
        return (
          this.fromDate === templates.tomorrow.toFormat(templates.format) &&
          this.toDate === templates.tomorrow.toFormat(templates.format)
        )
      case 'week':
        return (
          this.fromDate === templates.today.toFormat(templates.format) &&
          this.toDate === templates.endOfWeek.toFormat(templates.format)
        )
      case 'all':
        return this.fromDate === '' && this.toDate === ''
      default:
        return false
    }
  }

  private get isPeriodMultipleDays(): boolean {
    return this.fromDate !== this.toDate || !this.fromDate || !this.toDate
  }

  private getPeriodTemplates() {
    const format = 'yyyy-MM-dd'
    const today = DateTime.now()
    const tomorrow = today.plus(Duration.fromISO('P1D'))
    const startOfWeek = today.weekday > 1 ? today.minus(Duration.fromISO('P' + (today.weekday - 1) + 'D')) : today
    const endOfWeek = startOfWeek.plus(Duration.fromISO('P1W')).minus(Duration.fromISO('P1D'))
    return {
      format,
      today,
      tomorrow,
      startOfWeek,
      endOfWeek,
    }
  }

  private submitPeriod() {
    this.fromDate = this.fromDateInput
    this.toDate = this.toDateInput
  }

  private getTyreHotelReadyInfo(item) {
    if (!item.wheelChange) {
      return {
        status: false,
        title: this.$t('No wheel change'),
        icon: 'far fa-question-circle',
        description: this.$t('No wheel change associated with this booking'),
      }
    }
    if (item.wheelChange.executedAt) {
      return {
        status: true,
        title: this.$t('Completed'),
        icon: 'far fa-check-circle',
        description: this.$t('Associated wheel change has been completed'),
      }
    }
    if (item.wheelChange.cancelledAt) {
      return {
        status: false,
        title: this.$t('Cancelled'),
        icon: 'far fa-times-circle',
        description: this.$t('Associated wheel change has been cancelled'),
      }
    }
    const status = item.wheelChange.returnStockTransportBundle?.status || 'NoTransport'
    switch (status) {
      case 'NoTransport':
        return {
          status: true,
          icon: 'fa fa-home',
          description: this.$t('Tyre hotel is already at target location'),
        }
      case 'PendingSchedule':
        return {
          status: false,
          icon: 'far fa-clock',
          description: this.$t('Waiting for a booking before scheduling transport'),
        }
      case 'Scheduled':
        return {
          status: false,
          icon: 'fa fa-truck',
          description: this.$t('In transit, waiting for %s wheels to be sent'),
          values: [item.wheelChange.returnStockTransportBundle?.countTotal],
        }
      case 'InTransit':
        return {
          status: false,
          icon: 'fa fa-truck',
          description: this.$t('In transit, %s of %s sent, %s received'),
          values: [
            item.wheelChange.returnStockTransportBundle?.countSent,
            item.wheelChange.returnStockTransportBundle?.countTotal,
            item.wheelChange.returnStockTransportBundle?.countReceived,
          ],
        }
      case 'Received':
        return {
          status: true,
          icon: 'fa fa-home',
          description: this.$t('Tyre hotel has arrived at target location'),
        }
      case 'Cancelled':
        return {
          status: false,
          icon: 'far fa-times-circle',
          description: this.$t('Transport of tyre hotel was cancelled'),
        }
      default:
        return {
          status: false,
          icon: 'far fa-question-circle',
          description: this.$t('Unknown transport status'),
        }
    }
  }

  private getTyreHotelReadyStatus(item): boolean {
    return this.getTyreHotelReadyInfo(item).status
  }

  private getTyreHotelReadyIcon(item): string {
    return this.getTyreHotelReadyInfo(item).icon
  }

  private getTyreHotelReadyDescription(item): string {
    return this.getTyreHotelReadyInfo(item).description
  }

  private getTyreHotelReadyTitle(item): string {
    return this.$t(this.getTyreHotelReadyInfo(item).status ? 'Tyre hotel is ready' : 'Tyre hotel is not ready')
  }

  private getWheelChangeSeasonInfo(item) {
    if (!item.wheelChange) {
      return {
        icon: 'far fa-circle',
        color: '',
        text: this.$t('No associated wheel change'),
      }
    }
    const response = {
      icon: item.wheelChange.season === 'Winter' ? 'fa fa-snowflake' : 'fa fa-sun',
      color: item.wheelChange.season === 'Winter' ? 'blue' : '#efcb3b',
      text: this.$t('Change to ' + item.wheelChange.season.toLowerCase() + ' tyres'),
    }
    if (item.wheelChange.action === 'Terminate') {
      response.color = 'red'
      response.text = this.$t('Pick up ' + item.wheelChange.season.toLowerCase() + ' tyres and terminate')
    }
    return response
  }

  private getRowClass(item): string {
    return 'status-' + item.status.toLowerCase()
  }

  private castToBookings(items: Array<Record<string, unknown>>): Array<Booking> {
    const result = []
    for (let i = 0; i < items.length; i++) {
      result.push(new Booking(items[i]))
    }
    return result
  }

  private validateDates(showError = true) {
    if (!this.fromDateInput || !this.toDateInput) {
      if (showError) {
        vxm.alert.onAxiosError(this.$t('Both dates should be populated'))
      }
      return false
    }
    return true
  }
}
